enum RoutesMapper {
  HOME = '/',
  FORGET = '/forget',
  PARTNER_FORGET = '/partner/forget',
  PARTNER_LOGIN = '/partner/login',
  PARTNER_PIN = '/partner/pin',
  CASHIER_LOGIN = '/cashier/login',
  CASHIER_FORGET = '/cashier/forget',
  CASHIER_POS = '/cashier/pos',
  CASHIER_PIN = '/cashier/pin',
  CASHIER_CASHBOXCLOSES = `/cashier/cashboxcloses`,
  CASHIER_GWG_TRAINING = '/cashier/gwg-training',
  ADMIN_OVERVIEW = '/admin/overview',
  ADMIN_USERS = '/admin/users',
  ADMIN_DISTRIBUTORS = '/admin/distributors',
  ADMIN_PARTNERS = '/admin/partners',
  ADMIN_POSES = '/admin/poses',
  ADMIN_CASHIERS = '/admin/cashiers',
  ADMIN_TRANSACTIONS = '/admin/transactions',
  ADMIN_BALANCE_TRANSACTIONS = '/admin/balance/transactions',
  ADMIN_AUDIT = '/admin/audit',
  ADMIN_BALANCE = '/admin/balance',
  ADMIN_PAYMENT = '/admin/payment',
  ADMIN_CASHBOXCLOSES = `/admin/cashboxcloses`,
  ADMIN_MONEY_LAUNDRY_TRANSACTIONS = '/admin/money-laundry-transactions',
  ADMIN_MONEY_LAUNDRY = '/admin/money-laundry',
  ADMIN_FINANCIAL_STATUS = `/admin/reports/financial-status`,
  ADMIN_REPORTS_POS = `/admin/reports/reports-pos`,
  ADMIN_PARTNER_REPORTS = `/admin/reports/reports-partners`,
  ADMIN_REPORTS_DISTRIBUTOR = `/admin/reports/reports-distributors`,
  ADMIN_STATEMENTS = `/admin/statements`,
  ADMIN_ALL_REPORTS = '/admin/reports/all-reports',
  ADMIN_SETTINGS = '/admin/settings',
  ADMIN_MARKETING_MATERIAL = '/admin/marketing-material',
  ADMIN_QR_CODES = '/admin/qr-codes',
  ADMIN_PARTNER_GROUPS = '/admin/partner-groups',
  IMPRESSUM = '/impressum',
  TERMS_CONDITIONS = '/pdf/terms_conditions.pdf',
  PRIVACY_POLICY = '/pdf/privacy_policy.pdf',
  ADMIN_DASHBOARD = '/admin/dashboard',
}

export const getPageUrl = (pageUrl: RoutesMapper, id: number): string => {
  return `${pageUrl}/${id}`;
};

export default RoutesMapper;
