import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';

import device from '@helpers/breakpoints';

import RoutesMapper from '../src/constants/routes';

const HomeWrapper = styled.div`
  background-color: var(--primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  height: -webkit-fill-available;
`;

const ButtonWrapper = styled.div`
  display: flex;
  @media ${device.tablet} {
    flex-direction: column;
  }
`;

export const CustomLink = styled.div<{ isButton?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 32px;
  width: 200px;
  margin: 20px 10px;
  border-radius: 5px;
  text-align: center;
  background-color: var(--primary-button);
  color: var(--primary-text);
  font-size: 14px;
  text-transform: uppercase;
`;

export const Logo = styled.img`
  width: 300px;
  height: 200px;
  margin-bottom: 20px;
`;

const { CASHIER_LOGIN, PARTNER_LOGIN } = RoutesMapper;

const Home = (): JSX.Element => {
  return (
    <HomeWrapper>
      <Logo src="/images/logo-aircash-white.svg" />
      <ButtonWrapper>
        <Link href={PARTNER_LOGIN}>
          <CustomLink>Login als Unternehmer</CustomLink>
        </Link>
        <Link href={CASHIER_LOGIN}>
          <CustomLink>Login als Mitarbeiter</CustomLink>
        </Link>
      </ButtonWrapper>
    </HomeWrapper>
  );
};

export default Home;
